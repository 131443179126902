.one-piece-page {
    text-align: center;
}

.image-container {
    display: flex;           /* Use flexbox to center the content */
    justify-content: center; /* This will center the content horizontally */
    align-items: center;     /* This will center the content vertically if needed */
    flex-direction: column;  /* Stacks the images vertically */
    margin: auto;            /* Additionally centers the flex container if needed */
}

.image-container img {
    width: 95%;             /* Adjusts width to be responsive */
           /* Maximum width of image */
    margin: 40px 0;          /* Vertical margin, no horizontal margin */
    border-top: 8px solid black;
    border-bottom: 8px solid black;
}

body {
    background-color: #1d1b1b; /* Dark gray */
    color: #ffffff; /* White text for contrast */
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}
